<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-card-title>{{ type === 1 ? 'Check in' : 'Check out' }}</b-card-title>
      <b-row>
        <b-col>
          <div class="d-flex">
            <h6>{{ type === 1 ? 'Arrival date:' : 'Departure date' }}</h6>
            <h6 class="ml-1">
              {{ date.split(' ')[1] }}
            </h6>
          </div>
        </b-col>
        <b-col>
          <div class="d-flex">
            <h6>{{ type === 1 ? 'Check in time:' : 'Check out time:' }}</h6>
            <h6 class="ml-1">
              {{ dateCheck || 'Not yet' }}
            </h6>
          </div>
        </b-col>
      </b-row>
      <b-row class="p-1">
        <div style="width: 100%;">
          <h6 for="textarea-default">
            Guest notes to hotel
          </h6>
          <b-form-textarea
            id="textarea-rows"
            v-model="guestNote"
            placeholder="None"
            rows="3"
            readonly
          />
        </div>

      </b-row>
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardTitle, BRow, BCol, BFormTextarea,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BCardBody, BCardTitle, BRow, BCol, BFormTextarea,
  },
  props: {
    type: {
      type: Number,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    dateCheck: {
      type: String,
      required: true,
    },
    guestNote: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>

</style>
