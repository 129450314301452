<template>
  <b-card title="User Activity">
    <app-timeline v-if="activities.length">
      <app-timeline-item
        v-for="item in activities"
        :key="item.id"
        :title="item.title"
        :subtitle="item.subTitle"
        :time="formatDate(item.time)"
      >
        <div class="d-flex mb-1 mb-sm-0">
          <h6>{{ item.title }}</h6>
          <div class="ml-auto">
            <b-col>
              <b-button
                size="sm"
                variant="outline-danger"
                @click="cancelActivity(item)"
              >
                Cancel
              </b-button>
            </b-col>
          </div>
        </div>
        <div class="ml-2">
          <p>{{ item.subTitle }}</p>
          <small>
            {{ formatDate(item.time) }}
          </small>
        </div>
      </app-timeline-item>
    </app-timeline>
    <div v-else>
      <h6>No activities</h6>
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BMedia, BAvatar, BButton, BCol,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import moment from 'moment'
import store from '@/store'

export default {
  components: {
    BCard,
    BImg,
    BMedia,
    BAvatar,
    AppTimeline,
    AppTimelineItem,
    BButton,
    BCol,
  },
  props: {
    activities: {
      type: Array,
      required: true,
    },
  },
  methods: {
    formatDate(time) {
      return moment(time).utc().format('HH:mm:ss DD/MM/YYYY')
    },
    cancelActivity(item) {
      this.$bvModal
        .msgBoxConfirm('Please confirm that you want to cancel this activity.', {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            if (item.type === 'room-service') {
              store.dispatch('cukcuk/cancelOrder', { id: item.id })
                .then(() => {
                  this.$emit('refresh-activity')
                })
            } else {
              store.dispatch('customerFlow/cancelCustomerActivity', { id: item.id })
                .then(() => {
                  this.$emit('refresh-activity')
                })
            }
          }
        })
    },
  },
}
</script>

<style>

</style>
