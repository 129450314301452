<template>
  <b-card
    no-body
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-0">
        Res note
      </h5>
    </b-card-header>

    <b-card-body>
      <b-form-textarea
        id="textarea-default"
        v-model="resNote"
        placeholder="Textarea"
        rows="6"
        readonly
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BFormTextarea,
  },
  props: {
    resNote: {
      type: String,
      required: true,
    },
  },
}
</script>

<style>

</style>
