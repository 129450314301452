<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <!-- First Row -->
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>
      </b-row>

      <b-row>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-check
            :type="1"
            :date="userData.arrivalDate"
            :date-check="userData.checkInTime"
            :guest-note="userData.note"
          />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <user-view-check
            :type="2"
            :date="userData.departureDate"
            :date-check="userData.checkOutTime"
            :guest-note="userData.checkOutNote"
          />
        </b-col>

      </b-row>
      <b-row align-h="center">
        <b-col
          cols="12"
          lg="12"
        >
          <user-view-user-timeline-card
            :activities="listActivities"
            @refresh-activity="fetchActivity"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          lg="12"
        >
          <user-view-feed-rate
            :feed-back="userData.feedback"
            :overall-rating="userData.overallRating"
            :rating-list="ratingList"
            :rating-options="ratingOptions"
          />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { ref } from '@vue/composition-api'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
import UserViewResNote from './UserViewResNote.vue'
import UserViewUserTimelineCard from './UserViewUserTimelineCard.vue'
import UserViewCheck from './UserViewCheck.vue'
import UserViewFeedRate from './UserViewFeedRate.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    UserViewResNote,
    // Local Components
    UserViewUserInfoCard,
    UserViewUserTimelineCard,
    UserViewCheck,
    UserViewFeedRate,
  },
  setup() {
    const toast = useToast()
    const userData = ref(null)
    const ratingOptions = ref([])
    const ratingList = ref([])
    const listActivities = ref([])
    store.dispatch('customerFlow/fetchCustomerFlow', { id: router.currentRoute.params.id }).then(res => {
      console.log()
      userData.value = res.body
    }).catch(e => {
      console.log(e)
      userData.value = undefined
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching customer',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
    store.dispatch('customerFlow/fetchCustomerRating', { id: router.currentRoute.params.id }).then(res => {
      ratingList.value = res.body
    }).catch(e => {
      console.log(e)
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching customer rating',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })
    const fetchActivity = () => {
      store.dispatch('customerFlow/fetchCustomerActivty', { id: router.currentRoute.params.id }).then(res => {
        if (res.body.length) {
          listActivities.value = res.body.map(x => ({
            id: x.type === 'room-service' ? x.id : x.reserveId,
            title: x.name,
            type: x.type,
            subTitle: x.subTitle,
            time: x.type === 'room-service' ? x.createdAt : x.date,
          })).reverse()
          console.log(listActivities)
        }
      }).catch(e => {
        console.log(e)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching customer rating',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    }
    fetchActivity()
    store.dispatch('rating/fetchRatings', {
      take: 50,
      page: 1,
    }).then(res => {
      ratingOptions.value = res.body.data
    })
    return {
      userData,
      ratingOptions,
      ratingList,
      listActivities,
      fetchActivity,
    }
  },
}
</script>

<style>

</style>
