<template>
  <b-card
    no-body
  >
    <b-card-body>
      <b-row>
        <b-col>
          <!-- Overall Rating -->

          <h6
            for="textarea-default"
            class="mb-2"
          >
            Overall ratings: {{ overallRating || '0' }}
          </h6>
          <div
            v-if="ratingList.length"
          >
            <b-row
              v-for="rate in ratingList"
              :key="rate.id"
              class="d-flex mb-1"
            >
              <b-col md="3">
                <h6>{{ mapTitleRating(rate.ratingId) }}:</h6>
              </b-col>
              <b-col md="9">
                <b-form-rating
                  v-model="rate.rating"
                  variant="warning"
                  stars="5"
                  readonly
                />
              </b-col>
            </b-row>

          </div>
        </b-col>
        <b-col md="6">
          <h6 for="textarea-default">
            Feedback
          </h6>
          <b-form-textarea
            id="textarea-rows"
            v-model="feedBack"
            class="mt-2"
            placeholder="None"
            rows="3"
            readonly
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BFormTextarea, BRow, BCol, BFormGroup, BFormRating,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardBody,
    BFormTextarea,
    BRow,
    BCol,
    BFormGroup,
    BFormRating,
  },
  props: {
    feedBack: {
      type: String,
      required: true,
    },
    overallRating: {
      type: Number,
      required: true,
      default: 0,
    },
    ratingList: {
      type: Array,
      required: true,
    },
    ratingOptions: {
      type: Array,
      required: true,
    },
  },
  methods: {
    mapTitleRating(idRating) {
      return this.ratingOptions.find(x => x.id === idRating).title
    },
  },
}
</script>

<style>

</style>
