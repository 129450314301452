<template>
  <b-card>
    <q-r-code
      v-if="qrData"
      :is-show-qr.sync="isShowQr"
      :code-customer="qrData"
      :sur-name="surName"
      :res-code="resCode"
      :guest-given="guestGiven"
    />
    <b-row>
      <!-- User Info: Left col -->
      <b-col
        cols="21"
        xl="6"
        class="d-flex justify-content-between flex-column"
      >
        <!-- User Avatar & Action Buttons -->
        <div class="d-flex justify-content-start mt-2">
          <b-avatar
            :src="userData.avatar"
            :text="avatarText(userData.guestGiven)"
            :variant="`light-primary`"
            size="104px"
            rounded
          />
          <div class="d-flex flex-column ml-1 mt-1">
            <div class="mb-1">
              <h4 class="mb-0">
                {{ userData.guestGiven }}  {{ userData.surname }}
              </h4>
              <span class="card-text">{{ userData.email }}</span>
            </div>
            <div class="d-flex flex-wrap">
              <b-button
                block
                variant="primary"
                @click="showQR"
              >
                Generate QR
              </b-button>
            </div>
          </div>
        </div>
      </b-col>

      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">

              <span class="font-weight-bold">Res No</span>
            </th>
            <td class="pb-50">
              {{ userData.resCode }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">

              <span class="font-weight-bold">Guest No</span>
            </th>
            <td class="pb-50">
              {{ userData.guestId }}
            </td>
          </tr>

          <tr>
            <th class="pb-50">

              <span class="font-weight-bold">Status</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.status }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">

              <span class="font-weight-bold">Pax</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ userData.pax }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <span class="font-weight-bold">Room type</span>

            </th>
            <td class="pb-50">
              {{ userData.roomType }}

            </td>
          </tr>
          <tr>
            <th>
              <span class="font-weight-bold">Room</span>

            </th>
            <td>
              {{ userData.room }}

            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QRCode from '../modal/QRCode.vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, QRCode,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
  data() {
    return {
      isShowQr: false,
      surName: '',
      resCode: '',
      guestGiven: '',
      qrData: '',
    }
  },
  methods: {
    showQR() {
      this.$store.dispatch('customerFlow/fetchCustomerFlowQRLink', { id: router.currentRoute.params.id })
        .then(response => {
          this.qrData = response.body.url
          this.surName = this.userData.surname
          this.resCode = this.userData.resCode
          this.guestGiven = this.userData.guestGiven
        }).catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.error,
              icon: 'BellIcon',
              variant: 'danger',
            },
          })
        })
      this.isShowQr = true
    },
  },
}
</script>

<style>

</style>
